import type { Board, BoardMeta } from "@fola/schemas/folaBoard"
import type { Course, CourseMeta } from "@fola/schemas/course"
import { createLearningOutcome as _createLearningOutcome } from "@fola/state/folaBoard/utils"
import type { LearningOutcome } from "@fola/schemas/learningOutcome"
import { omit } from "@fola/utils/fn"

/**
 * Generates a unique id that will be replaced by the server later.
 */
export function generateId() {
	// const uidStore = useUidStore() // uidStore must only be called after pinia was initialized
	// return "CLIENT_" + uidStore.getUid()
	return crypto.randomUUID()
}

/**
 * @returns A new empty learningOutcome
 */
export const createLearningOutcome = (): LearningOutcome => _createLearningOutcome(generateId)

export interface LearningOutcomeEditorMeta {
	isDirty: boolean
	getFromCourse: boolean
	isNew: boolean
	isValid: boolean
	isOpen: boolean
}

/**
 * The v-model for a learning outcome editor
 */
export interface LearningOutcomeEditorModel {
	_meta: LearningOutcomeEditorMeta
	learningOutcome: LearningOutcome
}

/**
 * @returns The meta object for a learning outcome editor
 * @param isNew Whether the learning outcome is new
 */
export const createLoMeta = (isNew: boolean): LearningOutcomeEditorMeta => ({
	getFromCourse: true,
	isValid: true,
	isDirty: false,
	isOpen: false,
	isNew,
})

/**
 * @returns An empty learning outcome editor
 * @param isNew Whether the learning outcome is new
 */
export const createEmptyLoEditor = (isNew: boolean): LearningOutcomeEditorModel => ({
	learningOutcome: createLearningOutcome(),
	_meta: createLoMeta(isNew),
})

/**
 * the model for a member of a board or course
 */
interface Member {
	name: string
	avatar: string
	displayName: string
	role: "owner" | "member" | "guest"
}

/**
 * the v-model for the member status editor
 */
export type MemberStatusEditorModel = Member

export type ValidBoardMeta = Omit<BoardMeta, "id" | "objectVersion">

/**
 * the model for the board editor
 */
export type BoardEditorData = ValidBoardMeta & {
	learningOutcomes: Board["learningOutcomes"]
	members: Board["members"]
}

export type ValidCourseMeta = Omit<CourseMeta, "id" | "objectVersion">

/**
 * the model for the course editor
 */
export type CourseEditorData = ValidCourseMeta & {
	learningOutcomes: Course["learningOutcomes"]
	members: Course["members"]
}

/**
 * The result of a handler function
 *
 * @template T The type of the result, if any.
 */
export interface HandlerResult<T> {
	/** The actual result value, if any changes were made and the result was successful. */
	result: T | null
	/** If an operation was successful */
	ok: boolean
}

export const toBoardEditorData = (board: Board): BoardEditorData =>
	omit(board, ["learningUnits", "learningUnitsEntry", "cardStacks", "cards"])

export const toCourseEditorData = (course: Course): CourseEditorData => omit(course, ["folaBoards"])
