import type { RouteRecordRaw } from "vue-router"

import { useI18n as t } from "@/core/i18nStore"

const routes: RouteRecordRaw[] = [
	{
		path: "/collection",
		name: "colletion",
		component: () => import("./CollectionView.vue"),
		meta: {
			title: () => t().s?.folaBoard.collection,
		},
	},
]

export default routes
