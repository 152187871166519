/**
 * Interact with the auth API and store the user's authentication status.
 */

import type {
	AuthErrorSchema,
	GetEmailAndUsernameRequestSchema,
	RegisterRequestSchema,
	RegistrationErrors,
	ResetPasswordErrors,
	ResetPasswordRequestSchema,
	VerificationErrors,
} from "@fola/schemas/auth"

import client from "@/core/rpc"
import { TRPCClientError } from "@trpc/client"
import { useAuthStore } from "@/auth/store"

/**
 * Log in a user.
 *
 * @param usernameOrEmail
 * @param password
 * @returns true if the login was successful, false otherwise
 */
export async function login(usernameOrEmail: string, password: string) {
	try {
		const res = await client.login.mutate({ usernameOrEmail, password })
		useAuthStore().login(res)
		return true
	} catch (e) {
		console.error(e)
		if (e instanceof TRPCClientError) {
			// FIXME: the error messages sent by the server dont have the correct format, yet.
			useAuthStore().logout(e.message as AuthErrorSchema["error"])
			return false
		} else throw e
	}
}

/**
 * Check if the user is logged in. If they are, update the store with their username and admin status.
 * If they are not, store that they are logged out.
 *
 * @returns true if the user is logged in, false otherwise
 */
export async function checkLoggedIn() {
	try {
		const res = await client.checkAuthenticated.query()
		// user is already logged in, but maybe the admin status changed
		useAuthStore().login(res)
		return true
	} catch (e) {
		if (e instanceof TRPCClientError) {
			useAuthStore().logout(e.message as AuthErrorSchema["error"])
			return false
		} else throw e
	}
}

/**
 * Log out the user.
 *
 * @returns always true if the request succeeds
 */
export async function logout() {
	await client.logout.mutate()
	useAuthStore().logout()
	return true
}

/**
 * Register a user.
 * @param username
 * @param email
 * @param password
 * @returns true if the registration was successful, false otherwise
 */
export async function register(data: RegisterRequestSchema) {
	try {
		return await client.register.mutate(data)
	} catch (e) {
		if (e instanceof TRPCClientError) {
			// TODO: handle errors e.g. as notification
			return { error: e.message as RegistrationErrors }
		} else throw e
	}
}

export async function verifyEmail(token: string) {
	try {
		const res = await client.verifyEmail.mutate({ token })
		useAuthStore().login(res)
		return { username: res.username }
	} catch (e) {
		if (e instanceof TRPCClientError) {
			return { error: e.message as VerificationErrors }
		} else throw e
	}
}

/**
 * Sends an email to the user with a link to reset their password.
 * @param data username or email
 * @returns sends an email to the user with a link to reset their password
 */
export async function requestPasswordResetMail(data: GetEmailAndUsernameRequestSchema) {
	try {
		return await client.requestPasswordResetMail.mutate(data)
	} catch (e) {
		if (e instanceof TRPCClientError) {
			return { error: e.message as ResetPasswordErrors }
		} else throw e
	}
}

/**
 * Reset the password of a user.
 * @param username
 * @param password
 * @returns the username of the user or an error
 */
export async function resetPassword(data: ResetPasswordRequestSchema) {
	try {
		const res = await client.resetPassword.mutate(data)
		useAuthStore().login(res)
		return { username: res.username }
	} catch (e) {
		if (e instanceof TRPCClientError) {
			return { error: e.message as ResetPasswordErrors }
		} else throw e
	}
}
