import { defineStore } from "pinia"
import { ref } from "vue"

import type { CoreL10nStrings } from "./localization/en"

type SupportedLanguage = "en" | "nl" | "de"

/**
 * Map of language codes to promises that resolve to the localization strings for that language.
 * This is used to load the localization strings for a language lazily.
 */
const langPromises = {
	en: async () => (await import("./localization/en")).default,
	nl: async () => (await import("./localization/nl")).default,
	de: async () => (await import("./localization/de")).default,
} as Record<SupportedLanguage, () => Promise<CoreL10nStrings>>

/**
 * Store and switch the current localization. Use this to get the strings that are to be
 * displayed in any component. Use it like this:
 *
 * ```ts
 * import { useI18n } from "@/core/i18nStore"
 * const i18n = useI18n()
 * ```
 *
 * ... then later in your component:
 *
 * ```html
 * <!-- If you have a simple string -->
 * <p>{{ i18n.s?.someString }}</p>
 * <!-- If you have string that is parameterized as a function -->
 * <p>{{ i18n.s?.someStringWithParams("param1", "param2") }}</p>
 * ```
 */
export const useI18n = defineStore("i18n", () => {
	const lang = ref<SupportedLanguage>("en")
	const s = ref<CoreL10nStrings | null>(null)

	/**
	 * Switch to another language.
	 *
	 * @param newLang - The language to switch to
	 */
	async function changeLanguage(newLang: SupportedLanguage) {
		lang.value = newLang
		s.value = await langPromises[newLang]()
		document.documentElement.lang = newLang
	}

	/**
	 * Load the default language. We first try to load the language that
	 * the user has set in their browser. If that fails, we load English.
	 */
	async function init() {
		let foundLang = false
		for (const langCode of window.navigator.languages) {
			if (langCode in langPromises) {
				await changeLanguage(langCode as SupportedLanguage)
				foundLang = true
				break
			}
		}
		if (!foundLang) {
			await changeLanguage("en")
		}
	}
	init()

	return { lang, s, changeLanguage, init }
})
