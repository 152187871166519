import type { RouteRecordRaw } from "vue-router"
import { useI18n as t } from "@/core/i18nStore"

import LoginView from "@/auth/LoginView.vue"

const routes: RouteRecordRaw[] = [
	{
		path: "/login",
		name: "login",
		component: LoginView,
		meta: {
			noRequiresAuth: true,
			title: () => t().s?.auth.login,
		},
	},
	{
		path: "/me",
		name: "me",
		component: () => import("../user/UserSettingsView.vue"),
		meta: {
			title: () => t().s?.core.me,
		},
	},
	{
		path: "/register",
		name: "register",
		component: () => import("./RegistrationView.vue"),
		meta: {
			noRequiresAuth: true,
			title: () => t().s?.auth.register,
		},
	},
	{
		path: "/verify-email",
		name: "verify-email",
		component: () => import("./VerifyEmailView.vue"),
		meta: {
			noRequiresAuth: true,
			title: () => t().s?.auth.verifyEmail,
		},
	},
	{
		path: "/reset-password",
		name: "reset-password",
		component: () => import("./ResetPasswordView.vue"),
		meta: {
			noRequiresAuth: true,
			title: () => t().s?.auth.resetPassword,
		},
	},
	{
		path: "/verify-reset-password",
		name: "/verify-reset-password",
		component: () => import("./VerifyResetPasswordView.vue"),
		meta: {
			noRequiresAuth: true,
			title: () => t().s?.auth.verifyResetPasswort,
		},
	},
]

export default routes
