/**
 * load a config from the main html file
 */

/**
 * parse the json config from the script tag with the given id
 *
 * @param id id of the script tag containing the json config
 * @param init object to use if the script tag is not found or the json is invalid
 * @returns the parsed json or the init object
 */
export function getJsonScript<T>(id: string, init = {}) {
	try {
		return JSON.parse(document.getElementById(id)?.textContent ?? JSON.stringify(init)) as Partial<T>
	} catch (e) {
		console.error("Failed to parse config from main html file", e)
		return init
	}
}

const defaults = {
	env: "development",
	baseUrl: "",
	trpcBase: "/trpc",
	// captchaKey: "10000000-ffff-ffff-ffff-000000000001", //localhost key
	captchaKey: "081c4074-16dc-4cea-b510-caf4f32efa54", //fola.digital key
	registrationAccess: true,
}

export default { ...defaults, ...getJsonScript<typeof defaults>("config") }
