/* eslint-disable @typescript-eslint/no-explicit-any */

import type { NullishAsUndefinedObjectValues } from "./types"

/**
 * Set a value on an object as a function that returns the object.
 *
 * This is useful for functional programming, where you want to chain function calls.
 *
 * @param o object to set property on
 * @param key property name
 * @param value property value
 */
export function oSet<T, K extends keyof T>(o: T, key: K, value: T[K]): T
export function oSet<T, K extends string, V>(o: T, key: K, value: V): T & { [_ in K]: V }
export function oSet<T, K extends keyof T>(o: T, key: K, value: T[K]): T {
	o[key] = value
	return o
}

/**
 * Delete a property from an object as a function that returns the object.
 *
 * This is useful for functional programming, where you want to chain function calls.
 *
 * @param o object to delete property from
 * @param key property name
 */
export function oDel<T, K extends keyof T>(o: T, key: K): Omit<T, K> {
	delete o[key]
	return o
}

/**
 * Pick properties from an object as a function that returns the object.
 *
 * Analogous to typpescripts `Pick` type.
 *
 * @param o object to pick properties from
 * @param keys keys to pick
 */
export function pick<T extends object, K extends keyof T>(o: T, keys: K[]): Pick<T, K> {
	return keys.reduce((obj, key) => (key in o ? oSet(obj, key, o[key]) : obj), {} as Pick<T, K>)
}

/**
 * Omit properties from an object as a function that returns the object.
 *
 * Analogous to typpescripts `Omit` type.
 *
 * @param o object to omit properties from
 * @param keys keys to omit
 * @returns object without the omitted properties
 */
export function omit<T extends object, K extends keyof T>(o: T, keys: K[]): Omit<T, K> {
	return keys.reduce((obj, key) => oDel(obj, key) as any, { ...o })
}

/**
 * Convert all null values in an object to undefined.
 *
 * @param o the object to convert
 * @returns the object with all null values converted to undefined
 */
export function nullishAsUndefinedObjectValues<T extends object>(o: T): NullishAsUndefinedObjectValues<T> {
	return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, v ?? undefined])) as NullishAsUndefinedObjectValues<T>
}

/**
 * A function that does nothing.
 */
export const noop = () => {}

/**
 * @param arr array to peek into
 * @returns the last element of the array
 */
export function peek<T>(arr: T[]): T {
	return arr[arr.length - 1]
}

/**
 * @returns if any of the arguments is truthy
 * @param elems elements to check
 */
export function any(...elems: any[]): boolean {
	return elems.some((e) => !!e)
}

/**
 * @returns if all of the arguments are truthy
 * @param elems elements to check
 */
export function all(...elems: any[]): boolean {
	return elems.every((e) => !!e)
}

// export const _ = Symbol("skipArg")

// /**
//  * Curries a function.
//  *
//  * @param fn function to curry
//  * @param args arguments to curry the function with. Use `_` to skip an argument. If an argument
//  *             that you want to skip is the last argument, you can also just not pass it.
//  */
// export function curry<T extends any[], R>(fn: (...args: T) => R, ...args: (T | typeof _)[]): (...args: T) => R {
// 	return (...args2: T) => {
// 		let allArgs = args.map((a) => (a === _ ? args2.shift() : a))
// 		allArgs = allArgs.concat(args2)
// 		return fn(...(allArgs as T))
// 	}
// }

/**
 * Create a range of numbers.
 * @param end the end of the range
 */
export function range(end: number): number[]
/**
 * Create a range of numbers.
 * @param start the start of the range
 * @param end the end of the range
 * @param step the step size. Defaults to 1.
 */
export function range(start: number, end: number, step: number): number[]
export function range(start: number, end?: number, step = 1): number[] {
	if (end === undefined) {
		end = start
		start = 0
	}

	const arr = []
	for (let i = start; i < end; i += step) arr.push(i)
	return arr
}

/**
 * Zip multiple arrays together, like python's `zip`.
 *
 * from https://stackoverflow.com/a/70192772
 *
 * @param args arrays to zip
 * @returns an array of objects, where each object has the nth element of each array
 */
export function zip<T extends unknown[][]>(...args: T): { [K in keyof T]: T[K] extends (infer V)[] ? V : never }[] {
	const minLength = Math.min(...args.map((arr) => arr.length))
	// @ts-expect-error This is too much for ts
	return range(minLength).map((i) => args.map((arr) => arr[i]))
}

/**
 * Unzip an array of objects into multiple arrays.
 *
 * from https://stackoverflow.com/a/72650077
 *
 * @param arr
 * @returns
 */
export function unzip<T extends [...{ [K in keyof S]: S[K] }][], S extends any[]>(
	arr: [...T],
): T[0] extends infer A ? { [K in keyof A]: T[number][K & keyof T[number]][] } : never {
	const maxLength = Math.max(...arr.map((x) => x.length))

	return arr.reduce(
		(acc: any, val) => {
			for (let i = 0; i < val.length; i++) acc[i].push(val[i])
			return acc
		},
		Array.from(new Array(maxLength), () => []),
	)
}

/**
 * Transforms an array of objects into a map using the given object key's value as the map key.
 *
 * @param arr the array to be transformed
 * @param key the object's key which's value should be used as the map key
 * @returns the map
 */
export const arrToMap =
	<T extends object>(key: Extract<keyof T, string>) =>
	(arr: T[]): Map<T[typeof key], T> =>
		new Map(arr.map((e) => [e[key], e]))

/**
 * Transforms an array of objects into a map using the given object key's value as the map key.
 *
 * @param arr the array to be transformed
 * @param key the object's key which's value should be used as the map key
 * @returns the map
 */
export const arrToRecord =
	<T extends object, K extends keyof T = keyof T>(key: K) =>
	(arr: T[]): Record<K, T> =>
		arr.reduce((acc, e) => oSet(acc, e[key] as K, e), {} as Record<K, T>)
