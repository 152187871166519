<script setup lang="ts">
import { type LocationQueryValue, useRoute, useRouter } from "vue-router"
import { onBeforeMount, ref } from "vue"

import { login } from "@/auth/services"
import { useAuthStore } from "@/auth/store"
import { useI18n } from "@/core/i18nStore"

const i18n = useI18n()
const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()

const usernameOrEmail = ref("")
const password = ref("")

/**
 * Log in the user.
 *
 * this function will be called when the user submits the form it will call the login service and redirect the user to
 * the next page if the login was successful or show an error message if it failed the login service will set the
 * authStore.isAuthenticated to true if the login was successful and the authStore.errorMessage to an error message if
 * it failed the authStore.errorMessage will be displayed within the console and as a notification.
 *
 * @returns true if the login was successful
 * @returns false if the login failed
 */
async function submit() {
	const loggedIn = await login(usernameOrEmail.value, password.value)
	// the router push cannot be in the login service, because it would cause a
	// circular dependency: login service -> router -> login view -> login service
	if (loggedIn) {
		const next = route.query.next as LocationQueryValue
		router.push(next ?? "/")
	} else {
		alert("Login failed")
	}
}

// if the user is already logged in, redirect them to the next page if they are is not logged in, do nothing the
// info if user is logged in or not is stored in the browser's local storage this function will be called before
// the component is mounted it will be called again if the user logs in or logs out this is because the authStore.
// isAuthenticated is a reactive variable and the component will be re-rendered if it changes.

onBeforeMount(() => {
	if (authStore.isAuthenticated) {
		const next = route.query.next as LocationQueryValue
		router.push(next ?? "/personal-dashboard")
	}
})
</script>

<template>
	<main class="container centered my-3">
		<form @submit.prevent="submit">
			<h1>{{ i18n.s?.auth.logintitle }}</h1>
			<p>
				<label for="usernameOrEmail" class="form-label">{{ i18n.s?.auth.usernameOrEmail }}</label>
				<input name="usernameOrEmail" type="text" class="form-control" v-model="usernameOrEmail" />
			</p>
			<p>
				<label for="password" class="form-label">{{ i18n.s?.auth.password }}</label>
				<input name="password" type="password" class="form-control" v-model="password" />
			</p>
			<p>
				<a href="/reset-password" class="btn btn-link text-start ps-0" role="button">{{ i18n.s?.auth.resetAccount }}</a>
			</p>
			<p v-if="authStore.errorMessage && authStore.errorMessage !== 'notLoggedIn'" class="text-danger">
				{{ i18n.s?.auth.error[authStore.errorMessage] ?? authStore.errorMessage }}
			</p>
			<p>
				<button type="submit" class="btn btn-primary">{{ i18n.s?.auth.login }}</button>
				&nbsp;
				<a href="/register" class="btn btn-link text-start ps-0" role="button">{{ i18n.s?.auth.registerAccount }}</a>
			</p>
		</form>
	</main>
</template>

<style lang="scss" scoped>
form {
	width: 100%;
}

a {
	text-decoration: none;
}
</style>
