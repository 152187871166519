<!--
	This component is used to display notifications to the user. It is used by the App.vue component
	to display notifications that are stored in the notification store.

	NOTE: PLEASE DO NOT USE THIS COMPONENT DIRECTLY. Use the notification store instead.
-->

<script lang="ts" setup="">
import { onBeforeUnmount, onMounted, ref } from "vue"
import type { StoredNotification } from "@/core/notificationStore"
import { Toast } from "bootstrap"
import { useI18n } from "@/core/i18nStore"

const props = defineProps<{ notification: StoredNotification }>()
const emit = defineEmits(["close"])
const t = useI18n()

const toastEl = ref<HTMLDivElement>()
let toast: Toast

onMounted(() => {
	toast = new Toast(toastEl.value!)
	toast.show()
})

const close = (action?: () => void) => {
	toastEl.value!.addEventListener("hidden.bs.toast", () => {
		emit("close")
	})
	clearTimeout(props.notification.timeout)
	action?.()
	toast.hide()
}

onBeforeUnmount(() => {
	toast.dispose()
})
</script>

<template>
	<div ref="toastEl" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="false">
		<div class="toast-header">
			<span
				class="rounded-1 me-2"
				:class="'bg-' + (props.notification.level ?? 'info')"
				style="width: 1em; height: 1em"
			></span>
			<strong class="me-auto">{{ props.notification.title }}</strong>
			<button @click.prevent="close()" type="button" class="btn-close" :aria-label="t.s?.core.close"></button>
		</div>
		<div class="toast-body">
			<span>{{ props.notification.message }}</span>
			<div v-if="notification.actions" class="mt-2 pt-2 border-top">
				<button
					v-for="action in notification.actions"
					:key="action.label"
					@click="close(action.onClick)"
					class="btn btn-sm me-2"
					:class="'btn-' + (action.color ?? 'secondary')"
				>
					{{ action.label }}
				</button>
			</div>
		</div>
	</div>
</template>
