import { type CreateTRPCProxyClient, createTRPCProxyClient, httpLink } from "@trpc/client"
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server"

import type { RpcRouter } from "@fola/server/dist/core/routes"

import config from "./config"

/**
 * Create a client for tRPC. This is used to call the server's RPC methods.
 *
 * **CAUTION:** Before using it, you have to run `pnpm run build-deps` in the server to generate the types.
 */
const client: CreateTRPCProxyClient<RpcRouter> = createTRPCProxyClient<RpcRouter>({
	links: [
		httpLink({
			url: config.trpcBase,
		}),
	],
})

;(window as any).rpc = client
export default client

export type RouterOutput = inferRouterOutputs<RpcRouter>
export type RouterInput = inferRouterInputs<RpcRouter>

export type RpcResult<T extends (...args: any) => any> = Awaited<ReturnType<T>>
