import type { Store } from "pinia"

/**
 * Persist the state of a pinia store to localStorage on every mutation.
 *
 * @param store The store to persist
 */
export function persistStoreToLocalStorage(store: Store) {
	store.$subscribe((mutation, state) => {
		localStorage.setItem(mutation.storeId, JSON.stringify(state))
	})
}

/**
 * Restore the state of a pinia store from localStorage.
 *
 * @param store The store to restore
 */
export function restoreStoreFromLocalStorage(store: Store) {
	const state = localStorage.getItem(store.$id)
	if (state) {
		store.$patch(JSON.parse(state))
	}
}
