import type { RouteRecordRaw } from "vue-router"

const routes: RouteRecordRaw[] = [
	{
		path: "/s/:id",
		name: "folaBoard",
		component: () => import("./FolaBoardView.vue"),
		props: true,
		meta: {
			useGridLayout: true,
		},
	},
]

export default routes
