import { reactive, ref } from "vue"
import { defineStore } from "pinia"

type Levels = "success" | "danger" | "warning" | "info"
type BsColors = "primary" | "secondary" | "light" | "dark" | Levels

/**
 * Actions will be displayed as buttons in the notification.
 */
export interface Action {
	label: string
	color?: BsColors
	onClick: () => void
}

/**
 * This type is used as an argument to the `notify` method.
 *
 * @param title - The title of the notification.
 * @param message - The message to display to the user.
 * @param actions - Optional actions that the user can take in response to the notification.
 * @param level - The level of the notification. This determines the color of the notification.
 * @param autoCloseMs - The number of milliseconds after which the notification will be removed.
 */
export interface Notification {
	title: string
	message: string
	actions?: Action[]
	level?: Levels
	autoCloseMs?: number
}

/**
 * When a notification is displayed, it is stored as this type. It contains the notification
 * itself, plus an ID and a timeout id.
 *
 * @param id - The former is used as a key in the loop which shows the notifications components.
 * @param timeout - The latter is used to cancel the timeout when the
 *                  notification is closed before the timeout expires.
 */
export interface StoredNotification extends Notification {
	id: number
	timeout?: NodeJS.Timeout // actually a number, since we're in the browser, but I couldn't get TS to accept that
}

/**
 * Use this store to display notifications to the user. Notifications will be displayed as a toast
 * in the bottom right corner of the screen. You can also specify actions that the user can take in
 * response to the notification. They will be displayed as buttons. After an action or the close
 * button is clicked, the notification will be removed. You can also specify a timeout after which
 * the notification will be removed, via the `autoCloseMs` property.
 *
 * To send a notification, import the store and call the `notify` method:
 *
 * ```ts
 * import { useNotificationStore } from "@/core/notificationStore"
 *
 * const notifications = useNotificationStore()
 *
 * const sendHello = () => notifications.notify({
 *   title: "Hello",
 *   message: "This is a notification",
 *   actions: [{
 *     label: "Click me",
 * 	   onClick: () => console.log("Clicked!")
 *   }],
 *   level: "info",
 *   autoCloseMs: 5000
 * })
 * ```
 */
export const useNotificationStore = defineStore("notifications", () => {
	const notifications = reactive<StoredNotification[]>([])
	const nextId = ref(0)

	/**
	 * Display a notification to the user.
	 *
	 * @param notification The notification to display
	 */
	function notify(notification: Notification) {
		const id = nextId.value
		nextId.value++
		const newtification: StoredNotification = { ...notification, id }
		notifications.push(newtification)

		if (newtification.autoCloseMs) {
			newtification.timeout = setTimeout(() => {
				const loc = notifications.findIndex((n) => n.id === id)
				loc >= 0 && notifications.splice(loc, 1)
			}, newtification.autoCloseMs)
		}
	}

	/**
	 * Closes a notification.
	 *
	 * @param id The id of the notification to close.
	 */
	function close(id: number) {
		const loc = notifications.findIndex((n) => n.id === id)
		loc >= 0 && notifications.splice(loc, 1)
	}

	return { notifications, notify, close }
})
