// import folaBoardRoutes from "../folaBoard/routes"
import type { RouteRecordRaw } from "vue-router"

const routes: RouteRecordRaw[] = [
	{
		path: "/c/:id",
		name: "course",
		// component: () => import("../course/CourseView.vue"),
		component: () => import("./CourseView.vue"),
		props: true,

		meta: {
			useGridLayout: true,
		},
		children: [
			{
				path: "s/:boardId",
				name: "folaBoards",
				component: () => import("../folaBoard/FolaBoardView.vue"),
				meta: {
					useGridLayout: true,
				},
			},
		],
		// children: [...folaBoardRoutes], this breaks the state manager - maybe due to circular dependency if name is identical
	},
]

export default routes
