<!--
	Vue component which encapsulates the Bootstrap Dropdown component.

	Usage:

	There are two slots: `button-inner` and `items`.

	- The `button-inner` slot is used to provide the content of the button which toggles the dropdown.
	- The `items` slot is used to provide the content of the dropdown items. You should add only
		`<li>` elements to this slot. These need to have the class `dropdown-item`.

	Props:

	- You can add classes to the button by using the `btn-class` prop on the `BsDropdown` component.
		The default class is `btn btn-secondary dropdown-toggle`.
	- To decide whether the button is a `<button>` or an `<a>` element, use the `btn-type` prop. You
		can set it to either `button` or `a`. The default is `button`.
	- If you want to add attributes, classes etc. to the dropdown wrapper, you can just add any
		attribute to the component. The class which is added by default is `dropdown`. It will be
		overridden instead of being merged with your provided classes if you add a `class` attribute.

	Example:

	```vue
	<script setup lang="ts">
	import BsDropdown from "./components/BsDropdown.vue"
	</script>

	<template>
		<BsDropdown class="dropdown-center" btn-type="a" btn-class="nav-link">
			<template #button-inner>Dropdown</template>
			<template #items>
				<li class="dropdown-item">Item 1</li>
				<li class="dropdown-item">Item 2</li>
				<li class="dropdown-item">Item 3</li>
			</template>
		</BsDropdown>
	</template>
	```
-->

<script lang="ts" setup>
import { onBeforeUnmount, onMounted, ref } from "vue"
import { Dropdown } from "bootstrap"

const props = defineProps({
	btnClass: {
		type: String,
		default: "btn btn-secondary dropdown-toggle",
	},
	btnType: {
		type: String,
		default: "button",
	},
	menuClass: {
		type: String,
		default: "dropdown",
	},
	class: {
		type: String,
		default: "dropdown",
	},
})

const dropdownEl = ref<HTMLUListElement>()
let dropdown: Dropdown

onMounted(() => {
	dropdown = new Dropdown(dropdownEl.value!)
})

onBeforeUnmount(() => {
	dropdown.dispose()
})
</script>

<template>
	<div :class="props.class">
		<a
			v-if="props.btnType === 'a'"
			:class="props.btnClass"
			aria-expanded="false"
			data-bs-toggle="dropdown"
			href="#"
			role="button"
		>
			<slot name="button-inner"></slot>
		</a>
		<button
			v-else-if="props.btnType === 'button'"
			:class="props.btnClass"
			aria-expanded="false"
			data-bs-toggle="dropdown"
			type="button"
		>
			<slot name="button-inner"></slot>
		</button>
		<span v-else style="color: red">Invalid value for prop `btn-type`!</span>
		<ul class="dropdown-menu" :class="props.menuClass" ref="dropdownEl">
			<slot name="items"></slot>
		</ul>
	</div>
</template>
