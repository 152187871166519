import type {
	ActivityCard,
	AnyBoardCard,
	AnyCard,
	AwarenessInfo,
	Board,
	BoardCardStack,
	BoardLearningOutcome,
	BoardLearningUnit,
	BoardMeta,
	CardStack,
	InsightCard,
	LearningOutcomeMeta,
	LearningUnit,
	MediatoolCard,
} from "@fola/schemas/folaBoard"

import type * as t from "./types"

// ################################################### Functional ################################################### \\
// #region Functional

/**
 *
 * @param stack the card stack to be checked
 * @returns a boolean indicating whether the stack is empty
 */
export const isEmptyStack = (stack: CardStack | undefined): stack is CardStack =>
	!!(stack && !stack.activity.length && !stack.insight.length && !stack.mediatool.length)

/**
 * @returns whether a slot for a ceratin card ty in a card stack if filled
 * @param stack the stack to be checked
 * @param cardType the type of the card to be checked
 */
export const slotIsFull = (stack: CardStack | undefined, cardType: AnyBoardCard["type"]) =>
	stack && !!stack[cardType].length

/**
 * Get a card from the board, with the correct type
 *
 * @param id the id of the card to be found
 * @param cardType the type of the card to be found
 * @param state the board to be searched
 * @returns the card with the given id and type
 */
export function getCard<T extends AnyBoardCard["type"]>(
	id: string,
	cardType: T,
	state: Board,
): Extract<AnyBoardCard, { type: T }> | undefined
export function getCard<T extends AnyBoardCard["type"]>(
	id: string,
	cardType: T,
	cards: Board["cards"],
): Extract<AnyBoardCard, { type: T }> | undefined
export function getCard<T extends AnyBoardCard["type"]>(
	id: string,
	cardType: T,
	stateOrCards: Board | Board["cards"],
): Extract<AnyBoardCard, { type: T }> | undefined {
	const cards = "cards" in stateOrCards ? (stateOrCards.cards as Board["cards"]) : stateOrCards
	return cards[id]?.type == cardType ? (cards[id] as Extract<AnyBoardCard, { type: T }> | undefined) : undefined
}

// #endregion Functional

// ################################################### Procedural ################################################### \\
// #region Procedural

type InsertPosition = { after: string | null } | { before: string | null }

/**
 * Insert a learning unit into the board at the given position
 *
 * @param learnignUnit The learning unit to be inserted
 * @param state The board state
 * @param position The position where the learning unit should be inserted
 * @returns The newly created learning unit
 */
export function insertLearningUnit(
	learnignUnit: LearningUnit | BoardLearningUnit,
	state: Board,
	position: InsertPosition = { before: null },
): BoardLearningUnit {
	if ("after" in position ? position.after === learnignUnit.id : position.before === learnignUnit.id)
		throw new Error("A learning unit cannot be inserted after itself.")
	const newLu: BoardLearningUnit = Object.assign({}, learnignUnit, { link: { prev: null, next: null } })

	if (!state.learningUnitsEntry) {
		state.learningUnitsEntry = { first: newLu.id, last: newLu.id }
	} else {
		const after =
			"after" in position
				? position.after
				: position.before === null
					? state.learningUnitsEntry.last
					: state.learningUnits[position.before]?.link.prev

		if (after === undefined || (after && !state.learningUnits[after])) {
			throw new Error("The learning unit id that was given as a position to insert at does not exist.")
		} else if (after === null) {
			const next = state.learningUnits[state.learningUnitsEntry.first]!
			newLu.link.next = next.id
			next.link.prev = newLu.id
			state.learningUnitsEntry.first = newLu.id
		} else {
			const prev = state.learningUnits[after]!
			newLu.link.prev = prev.id
			newLu.link.next = prev.link.next
			const next = prev.link.next ? state.learningUnits[prev.link.next]! : null
			if (next) next.link.prev = newLu.id
			else state.learningUnitsEntry.last = newLu.id
			prev.link.next = newLu.id
		}
	}

	state.learningUnits[newLu.id] = newLu
	return newLu
}

/**
 * Insert a card stack into the learning unit at the given position
 *
 * @param stack The stack to be inserted
 * @param learningUnit The learning unit where the stack should be inserted
 * @param state The board state
 * @param position The position where the stack should be inserted
 * @returns The newly created stack
 */
export function insertStack(
	stack: CardStack | BoardCardStack,
	learningUnit: BoardLearningUnit,
	state: Board,
	position?: InsertPosition,
): BoardCardStack
/**
 * Insert a card stack into the learning unit at the given position
 *
 * @param stack The stack to be inserted
 * @param learningUnitId The if of the learning unit where the stack should be inserted
 * @param state The board state
 * @param position The position where the stack should be inserted
 * @returns The newly created stack
 */
export function insertStack(
	stack: CardStack | BoardCardStack,
	learningUnitId: string,
	state: Board,
	position?: InsertPosition,
): BoardCardStack
export function insertStack(
	stack: CardStack | BoardCardStack,
	learningUnitOrId: BoardLearningUnit | string,
	state: Board,
	position: InsertPosition = { before: null },
): BoardCardStack {
	if ("after" in position ? position.after === stack.id : position.before === stack.id)
		throw new Error("A stack cannot be inserted before/after itself.")
	const learningUnit = typeof learningUnitOrId == "string" ? state.learningUnits[learningUnitOrId] : learningUnitOrId
	if (!learningUnit) throw new DoesNotExistError("LU for insertion", learningUnitOrId as string)

	const newStack: BoardCardStack = Object.assign({}, stack, {
		link: { usedIn: learningUnit.id, prev: null, next: null },
	})

	if (!learningUnit.cardStacks) {
		learningUnit.cardStacks = { first: newStack.id, last: newStack.id }
	} else {
		const after =
			"after" in position
				? position.after
				: position.before === null
					? learningUnit.cardStacks.last
					: state.cardStacks[position.before]?.link.prev

		if (after === undefined || (after && !state.cardStacks[after])) {
			const errId = after ?? (("before" in position && position.before) || "")
			throw new DoesNotExistError("stack for insertion", errId)
		} else if (after === null) {
			const prev = state.cardStacks[learningUnit.cardStacks.first]!
			newStack.link.next = learningUnit.cardStacks.first
			learningUnit.cardStacks.first = newStack.id
			prev.link.prev = newStack.id
		} else {
			const prev = state.cardStacks[after]!
			newStack.link.prev = prev.id
			newStack.link.next = prev.link.next
			const next = prev.link.next ? state.cardStacks[prev.link.next]! : null
			if (next) next.link.prev = newStack.id
			else learningUnit.cardStacks.last = newStack.id
			prev.link.next = newStack.id
		}
	}

	state.cardStacks[newStack.id] = newStack
	return newStack
}

/**
 * Insert a card into a stack and the board
 * @param card The card to be inserted
 * @param stack The stack where the card should be inserted
 * @param state The board state
 * @returns The newly created card
 */
export function insertCard<T extends AnyCard>(
	card: T,
	stack: CardStack,
	state: Board,
): Extract<AnyBoardCard, { type: T["type"] }>
/**
 * Insert a card into a stack and the board
 * @param card The card to be inserted
 * @param stackId The id of the stack where the card should be inserted
 * @param state The board state
 * @returns The newly created card
 */
export function insertCard<T extends AnyCard>(
	card: T,
	stackId: string,
	state: Board,
): Extract<AnyBoardCard, { type: T["type"] }>
export function insertCard<T extends AnyCard>(
	card: T,
	stackOrId: CardStack | string,
	state: Board,
): Extract<AnyBoardCard, { type: T["type"] }> {
	const stack = typeof stackOrId == "string" ? state.cardStacks[stackOrId] : stackOrId
	if (!stack) throw new DoesNotExistError("stack", stackOrId as string)
	stack[card.type].push(card.id)
	type OutputType = Extract<AnyBoardCard, { type: T["type"] }>
	const newCard = Object.assign({}, card, { link: { usedIn: stack.id } }) as unknown as OutputType
	state.cards[card.id] = newCard
	return newCard
}

/**
 * Attaches a learning outcome to a an insight card. This only works if the learning outcome exists.
 *
 * @param learningOutome The learning outcome to attach
 * @param card The card to attach the learning outcome to
 * @param state The board state
 */
export function attachLearningOutcomeToInsight(
	learningOutome: BoardLearningOutcome,
	card: InsightCard,
	state: Board,
): void
/**
 * Attaches a learning outcome to a an insight card. This only works if the learning outcome exists.
 *
 * @param learningOutomeId The id of the learning outcome to attach
 * @param card The card to attach the learning outcome to
 * @param state The board state
 */
export function attachLearningOutcomeToInsight(learningOutomeId: string, card: InsightCard, state: Board): void
/**
 * Attaches a learning outcome to a an insight card. This only works if the learning outcome exists.
 *
 * @param learningOutome The learning outcome to attach
 * @param cardId The id of the card to attach the learning outcome to
 * @param state The board state
 */
export function attachLearningOutcomeToInsight(learningOutome: BoardLearningOutcome, cardId: string, state: Board): void
/**
 * Attaches a learning outcome to a an insight card. This only works if the learning outcome exists.
 *
 * @param learningOutomeId The id of the learning outcome to attach
 * @param cardId The id of the card to attach the learning outcome to
 * @param state The board state
 */
export function attachLearningOutcomeToInsight(learningOutomeId: string, cardId: string, state: Board): void
export function attachLearningOutcomeToInsight(
	learningOutomeOrId: BoardLearningOutcome | string,
	cardOrId: InsightCard | string,
	state: Board,
): void {
	const learningOutome =
		typeof learningOutomeOrId == "string" ? state.learningOutcomes[learningOutomeOrId] : learningOutomeOrId
	if (!learningOutome) throw new DoesNotExistError("learning outcome", learningOutomeOrId as string)
	const card = typeof cardOrId == "string" ? getCard(cardOrId, "insight", state) : cardOrId
	if (!card) throw new DoesNotExistError("card", cardOrId as string)

	if (card.learningOutcome) {
		// delete the card from the old learning outcome if it exists
		const oldOutcome = state.learningOutcomes[card.learningOutcome]
		if (oldOutcome) oldOutcome.paths = oldOutcome.paths.filter((p) => !(p.type == "insightCard" && p.card == card.id))
	}

	card.learningOutcome = learningOutome.id
	const lo = state.learningOutcomes[card.learningOutcome]
	if (lo) lo.paths.push({ type: "insightCard", card: card.id })
	else card.learningOutcome = null
}

/**
 * Attaches a learning outcome to a card if it is an insight card and the card has a learning outcome set.
 * @param card the card to attach the learning outcome to
 * @param state the board state
 */
export function maybeAttachLearningOutcomeToCard(card: AnyBoardCard, state: Board): void
/**
 * Attaches a learning outcome to a card if it is an insight card and the card has a learning outcome set.
 * @param cardId the id of the card to attach the learning outcome to
 * @param state the board state
 */
export function maybeAttachLearningOutcomeToCard(cardId: string, state: Board): void
export function maybeAttachLearningOutcomeToCard(cardOrId: AnyBoardCard | string, state: Board): void {
	const card = typeof cardOrId == "string" ? state.cards[cardOrId] : cardOrId
	if (card && card.type == "insight" && card.learningOutcome) {
		const lo = state.learningOutcomes[card.learningOutcome]
		if (lo) attachLearningOutcomeToInsight(lo, card, state)
	}
}

/**
 * Attaches the learning outcomes to a learning unit
 * @param learningOutcomeMetas the learning outcomes to attach in the format that is used in the LUs
 * @param lu the learning unit to attach the learning outcomes to
 * @param state the board state
 */
export function attachOutcomesToUnit(learningOutcomeMetas: LearningOutcomeMeta[], lu: LearningUnit, state: Board): void
/**
 * Attaches the learning outcomes to a learning unit
 * @param learningOutcomeMetas the learning outcomes to attach in the format that is used in the LUs
 * @param luId The id of the learning unit to attach the learning outcomes to
 * @param state the board state
 */
export function attachOutcomesToUnit(learningOutcomeMetas: LearningOutcomeMeta[], luId: string, state: Board): void
export function attachOutcomesToUnit(
	learningOutcomeMetas: LearningOutcomeMeta[],
	luOrId: LearningUnit | string,
	state: Board,
): void {
	const lu = typeof luOrId == "string" ? state.learningUnits[luOrId] : luOrId
	if (!lu) throw new DoesNotExistError("learning unit", luOrId as string)
	for (let i = learningOutcomeMetas.length - 1; i >= 0; i--) {
		const lo = state.learningOutcomes[learningOutcomeMetas[i].learningOutcome]
		if (!lo)
			learningOutcomeMetas.splice(i, 1) // remove invalid learning outcomes
		else if (!lo.paths.some((path) => path.type == "learningUnit" && path.learningUnit == lu.id))
			lo.paths.push({ type: "learningUnit", learningUnit: lu.id }) // attach LU to learning outcome if not present
	}
	lu.learningOutcomes = learningOutcomeMetas
}

/**
 * Unlink a stack from a learning unit
 * @param stack the stack to be unlinked
 * @param state the board state
 * @returns whether the stack was successfully unlinked
 */
export function unlinkStack(stack: BoardCardStack, state: Board): boolean
/**
 * Unlink a stack from a learning unit
 * @param stackId the id of the stack to be unlinked
 * @param state the board state
 * @returns whether the stack was successfully unlinked
 */
export function unlinkStack(stackId: string, state: Board): boolean
export function unlinkStack(stackOrId: BoardCardStack | string, state: Board): boolean {
	const stack = typeof stackOrId == "string" ? state.cardStacks[stackOrId] : stackOrId
	if (!stack) return false

	const learningUnit = state.learningUnits[stack.link.usedIn]
	if (!learningUnit) return false

	const prev = stack.link.prev ? state.cardStacks[stack.link.prev] : null
	const next = stack.link.next ? state.cardStacks[stack.link.next] : null
	if (prev === undefined || next === undefined) return false

	if (prev !== null && next !== null) {
		// this stack is between two other stacks
		prev.link.next = stack.link.next
		next.link.prev = stack.link.prev
	} else if (prev !== null && next === null) {
		// this stack follows another stack and it is the last stack
		learningUnit.cardStacks!.last = prev.id
		prev.link.next = null
	} else if (prev === null && next !== null) {
		// this stack is the first stack and there is another stack after it
		learningUnit.cardStacks!.first = next.id
		next.link.prev = null
	} else {
		// this is the only stack
		learningUnit.cardStacks = null
	}
	stack.link = { usedIn: stack.link.usedIn, prev: null, next: null }
	return true
}

/**
 * Unlink a learning unit from the board
 * @param learningUnit the learning unit to be unlinked
 * @param state the board state
 * @returns whether the learning unit was successfully unlinked
 */
export function unlinkLearningUnit(learningUnit: BoardLearningUnit, state: Board): boolean
/**
 * Unlink a learning unit from the board
 * @param learningUnitId the id of the learning unit to be unlinked
 * @param state the board state
 * @returns whether the learning unit was successfully unlinked
 */
export function unlinkLearningUnit(learningUnitId: string, state: Board): boolean
export function unlinkLearningUnit(learningUnitOrId: BoardLearningUnit | string, state: Board): boolean {
	const learningUnit = typeof learningUnitOrId == "string" ? state.learningUnits[learningUnitOrId] : learningUnitOrId
	if (!learningUnit) return false

	const prevLu = learningUnit.link.prev ? state.learningUnits[learningUnit.link.prev] : null
	const nextLu = learningUnit.link.next ? state.learningUnits[learningUnit.link.next] : null
	if (prevLu === undefined || nextLu === undefined) return false

	if (prevLu !== null && nextLu !== null) {
		// this learning unit is between two other learning units
		prevLu.link.next = learningUnit.link.next
		nextLu.link.prev = learningUnit.link.prev
	} else if (prevLu !== null && nextLu === null) {
		// this learning unit follows another learning unit and it is the last learning unit
		state.learningUnitsEntry!.last = prevLu.id
		prevLu.link.next = null
	} else if (prevLu === null && nextLu !== null) {
		// this learning unit is the first learning unit and there is another learning unit after it
		state.learningUnitsEntry!.first = nextLu.id
		nextLu.link.prev = null
	} else {
		// this is the only learning unit
		state.learningUnitsEntry = null
	}
	learningUnit.link = { prev: null, next: null }
	return true
}

// #endregion Procedural

// ################################################### Generators ################################################### \\
// #region Generators

type LUGen = Generator<BoardLearningUnit, null, void>
type LuGenArgs = { reversed?: boolean; skip?: number; startLu?: BoardLearningUnit | string | null }
const defaultLuGenArgs: Required<LuGenArgs> = { reversed: false, skip: 0, startLu: null }

/**
 * Iterates over a linked list of learning units
 * @param state the state to iterate over
 * @param options options for the generator
 * @param options.reversed whether to iterate in reverse order
 * @param options.startFromId the id of the learning unit to start iterating from.
 *        This will be the first LU to be emitted.
 */
export function* iterLUs(state: Board, options: LuGenArgs = {}): LUGen {
	const { reversed, startLu: startFromLuOrId } = Object.assign({}, defaultLuGenArgs, options)
	let lu: BoardLearningUnit | undefined
	if (startFromLuOrId !== undefined && typeof startFromLuOrId == "string")
		lu = state.learningUnits[startFromLuOrId] // id
	else if (startFromLuOrId)
		lu = startFromLuOrId // learning unit
	else if (state.learningUnitsEntry)
		lu = state.learningUnits[reversed ? state.learningUnitsEntry.last : state.learningUnitsEntry.first] // fist / last

	const seen = new Set<string>()
	let visitedPreviously: string | null = null
	let skip = options.skip ?? defaultLuGenArgs.skip
	while (lu) {
		if (seen.has(lu.id)) throw new CircularReferenceError(lu.id, visitedPreviously ?? "null")

		if (skip) skip--
		else yield lu

		seen.add(lu.id)
		visitedPreviously = lu.id

		if (!reversed && lu.link.next) lu = state.learningUnits[lu.link.next]
		else if (reversed && lu.link.prev) lu = state.learningUnits[lu.link.prev]
		else lu = undefined
	}
	return null
}

type StackGen = Generator<BoardCardStack, null, void>
type StackGenArgs = { reversed?: boolean; skip?: number }
const defaultStackGenArgs: Required<StackGenArgs> = { reversed: false, skip: 0 }

/**
 * Iterates over all card stacks in a learning unit
 * @param learningUnit The learning unit to iterate over
 * @param state The board state
 * @param options Options for the generator
 * @param options.reversed Whether to iterate in reverse order
 * @param options.skip The number of stacks to skip over
 * @returns A generator that yields all card stacks in the learning unit
 */
export function iterStacks(learningUnit: LearningUnit, state: Board, options?: StackGenArgs): StackGen
/**
 * Iterates over all card stacks in a learning unit
 * @param startCardStack The stack to begin iterating from. This will be the first stack to be emitted.
 * @param state The board state
 * @param options Options for the generator
 * @param options.reversed Whether to iterate in reverse order
 * @param options.skip The number of stacks to skip over
 * @returns A generator that yields all card stacks in the learning unit
 */
export function iterStacks(startCardStack: BoardCardStack, state: Board, options?: StackGenArgs): StackGen
/**
 * Iterates over all card stacks in a learning unit
 * @param startCardStackId The id of the stack to begin iterating from. This will be the first stack to be emitted.
 * @param state The board state
 * @param options Options for the generator
 * @param options.reversed Whether to iterate in reverse order
 * @param options.skip The number of stacks to skip over
 * @returns A generator that yields all card stacks in the learning unit
 */
export function iterStacks(startCardStackId: string, state: Board, options?: StackGenArgs): StackGen
export function* iterStacks(
	luOrStackOrStackId: LearningUnit | BoardCardStack | string,
	state: Board,
	options: StackGenArgs = {},
): StackGen {
	const reversed = options.reversed ?? defaultStackGenArgs.reversed
	let stack: BoardCardStack | undefined = undefined
	if (typeof luOrStackOrStackId == "string")
		stack = state.cardStacks[luOrStackOrStackId] // stack id
	else if ("cardStacks" in luOrStackOrStackId && luOrStackOrStackId.cardStacks)
		stack = state.cardStacks[reversed ? luOrStackOrStackId.cardStacks.last : luOrStackOrStackId.cardStacks.first] // LU
	else if ("activity" in luOrStackOrStackId) stack = luOrStackOrStackId // stack

	const seen = new Set<string>()
	let previousStack: string | null = null
	let skip = options.skip ?? defaultStackGenArgs.skip
	while (stack) {
		if (seen.has(stack.id)) throw new CircularReferenceError(stack.id, previousStack ?? "null")

		if (skip) skip--
		else yield stack

		seen.add(stack.id)
		previousStack = stack.id

		if (!reversed && stack.link.next) stack = state.cardStacks[stack.link.next]
		else if (reversed && stack.link.prev) stack = state.cardStacks[stack.link.prev]
		else stack = undefined
	}
	return null
}

// #endregion Generators

// #################################################### Factory ##################################################### \\
// #region Factory

export const createBoardMeta = (provideId: t.IdProvider | string): BoardMeta => ({
	id: typeof provideId == "string" ? provideId : provideId(),
	objectVersion: 1,
	title: "",
	description: "",
	version: 2 as const,
	learningTime: 0,
	folaPhase: "Design",
	sessionDuration: 0,
})

/**
 * @returns A new empty board
 */
export const createBoard = (provideId: t.IdProvider | string): Board => ({
	...createBoardMeta(provideId),
	learningUnitsEntry: null,
	learningUnits: {},
	cardStacks: {},
	cards: {},
	learningOutcomes: {},
	members: {},
	learningTime: 0,
	sessionDuration: 0,
	courseId: null,
	updatedAt: null,
})

/**
 * @returns A new empty card stack
 * @param provideId A function that generates ids or a string that will be used as the id
 */
export const createStack = (provideId: t.IdProvider | string): CardStack => {
	return {
		id: typeof provideId == "string" ? provideId : provideId(),
		version: 2,
		objectVersion: 1,
		activity: [],
		insight: [],
		mediatool: [],
	}
}

/**
 * @returns A new empty learning unit
 * @param provideId A function that generates ids or a string that will be used as the id
 */
export const createLearningUnit = (provideId: t.IdProvider | string): LearningUnit => {
	return {
		id: typeof provideId == "string" ? provideId : provideId(),
		version: 2,
		objectVersion: 1,

		title: "",
		description: "",
		cardStacks: null,
		learningTime: 0,
		supervisionExpenditures: 0,
		learningOutcomes: [],
	}
}

/**
 * @returns A new empty learning outcome
 * @param provideId A function that generates ids or a string that will be used as the id
 */
export const createLearningOutcome = (provideId: t.IdProvider | string): BoardLearningOutcome => ({
	id: typeof provideId == "string" ? provideId : provideId(),
	version: 2,
	objectVersion: 1,
	title: "",
	description: "",
	bloomLevel: null,
	paths: [],
})

/**
 * @returns A new awareness info object
 */
export const createAwarenessInfo = (): AwarenessInfo => ({
	objectVersion: 1,
	usersOnline: new Map<string, number>(),
})

export const createInsightCard = (provideId: t.IdProvider | string): InsightCard => ({
	type: "insight",
	version: 2,
	objectVersion: 1,
	id: typeof provideId == "string" ? provideId : provideId(),
	title: "",
	description: "",
	createdFromTemplate: null,
	image: null,
	dataFields: [],
	indicators: [],
	learningOutcome: null,
})

export const createMediatoolCard = (provideId: t.IdProvider | string): MediatoolCard => ({
	type: "mediatool",
	version: 2,
	objectVersion: 1,
	id: typeof provideId == "string" ? provideId : provideId(),
	title: "",
	description: "",
	createdFromTemplate: null,
	image: null,
	category: null,
	ownCategory: null,
	ownTitle: null,
})

export const createActivityCard = (provideId: t.IdProvider | string): ActivityCard => ({
	type: "activity",
	version: 2,
	objectVersion: 1,
	id: typeof provideId == "string" ? provideId : provideId(),
	title: "",
	description: "",
	createdFromTemplate: null,
	image: null,
	activityType: null,
	assessment: false,
	interactionType: null,
	learningTime: 0,
	learningTimeMode: null,
	socialForm: null,
	supervisionExpenditures: 0,
	teachingModality: null,
})

export const createCard = <CardType extends AnyCard["type"]>(
	provideId: t.IdProvider | string,
	type: CardType,
): Extract<AnyCard, { type: CardType }> => {
	switch (type) {
		case "activity":
			return createActivityCard(provideId) as Extract<AnyCard, { type: CardType }>
		case "insight":
			return createInsightCard(provideId) as Extract<AnyCard, { type: CardType }>
		case "mediatool":
			return createMediatoolCard(provideId) as Extract<AnyCard, { type: CardType }>
		default:
			throw new Error(`Unknown card type: ${type}`)
	}
}

// #endregion Factory

// ################################################### Invariants ################################################### \\
// #region Invariants

/**
 * Check the consistency of the board state.
 *
 * This will check if all cards, card stacks and learning units are correctly linked.
 *
 * @param state the state to be checked
 */
export function ensureConsistency(state: Board): void {
	// TODO: Implement
	throw new Error("Not implemented")
}

/**
 * Delete a stack if it is empty.
 *
 * @param stack The stack to be deleted
 * @param state The board state
 * @returns Whether the stack was deleted. Returns false if the stack was not empty or could not be unlinked.
 */
export function maybeDeleteEmptyCardStack(stack: BoardCardStack, state: Board): boolean
/**
 * Delete a stack if it is empty.
 *
 * @param stackId The id of the stack to be deleted
 * @param state The board state
 * @returns Whether the stack was deleted. Returns false if the stack was not empty or could not be unlinked.
 */
export function maybeDeleteEmptyCardStack(stackId: string, state: Board): boolean
export function maybeDeleteEmptyCardStack(stackOrId: BoardCardStack | string, state: Board): boolean {
	const stack = typeof stackOrId == "string" ? state.cardStacks[stackOrId] : stackOrId
	if (isEmptyStack(stack) && unlinkStack(stack, state)) {
		delete state.cardStacks[stack.id]
		return true
	}
	return false
}

// #endregion Invariants

// ##################################################### Errors ##################################################### \\
// #region Errors
export class UpdateError extends Error {
	update: t.BoardUpdate

	constructor(message: string, update: t.BoardUpdate) {
		super(message)
		this.name = "UpdateError"
		this.update = update
	}
}

class CircularReferenceError extends Error {
	constructor(current: string, previous: string) {
		super(`Circular reference detected: ${previous} -> ${current}`)
		this.name = "CircularReferenceError"
	}
}

class DoesNotExistError extends Error {
	constructor(type: string, id: string) {
		super(`${type} with id ${id} does not exist.`)
		this.name = "DoesNotExistError"
	}
}
// #endregion Errors
