import type { RouteRecordRaw } from "vue-router"

import { useI18n as t } from "@/core/i18nStore"

const routes: RouteRecordRaw[] = [
	{
		path: "/personal-dashboard",
		name: "personal-dashboard",
		component: () => import("./PersonalDashboardView.vue"),
		meta: {
			title: () => t().s?.personalDashboard.title,
		},
	},
]

export default routes
