/**
 * This is the main router for the application. It is responsible for setting up
 * the routes for the application and loading the appropriate views for each route.
 *
 * If you want to add a new route, you can do so by importing the route from your module
 * and adding it to the routes array below. If you want your route to be a sub route of
 * a route that is added here, have a look at the vue-router documentation. That you can
 * find here: https://router.vuejs.org/guide/essentials/nested-routes.html
 */

import { createRouter, createWebHistory } from "vue-router"

import authRoutes from "@/auth/routes"
import boardRoutes from "@/folaBoard/routes"
import collectionRoutes from "@/collection/routes"
import courseRoutes from "@/course/routes"
import personalDashboardRoutes from "@/personalDashboard/routes"
import { useI18n as t } from "./i18nStore"
import { useAuthStore } from "@/auth/store"
import { useDashboardStore } from "@/personalDashboard/store"

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: "/",
			name: "home",
			component: () => import("./HomeView.vue"),
			meta: {
				noRequiresAuth: true,
				title: () => t().s?.core.home,
			},
		},
		{
			path: "/about",
			name: "about",
			meta: {
				noRequiresAuth: true,
				title: () => t().s?.core.about,
			},
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import("./AboutView.vue"),
		},
		{
			path: "/privacypolicy",
			name: "privacypolicy",
			meta: {
				noRequiresAuth: true,
				title: () => t().s?.core.privacyPolicy,
			},
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import("./PrivacypolicyView.vue"),
		},
		{
			path: "/legalnotice",
			name: "legalnotice",
			meta: {
				noRequiresAuth: true,
				title: () => t().s?.core.legalnotice,
			},
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import("./LegalNoticeView.vue"),
		},
		{
			path: "/accessibility",
			name: "accessibility",
			meta: {
				noRequiresAuth: true,
				title: () => t().s?.core.accessibility,
			},
			// route level code-splitting
			// this generates a separate chunk (About.[hash].js) for this route
			// which is lazy-loaded when the route is visited.
			component: () => import("./AccessibilityView.vue"),
		},
		{
			path: "/404",
			name: "404",
			meta: {
				noRequiresAuth: true,
				title: () => t().s?.core.notFound,
			},
			component: () => import("./404View.vue"),
		},
		...authRoutes,
		...boardRoutes,
		...courseRoutes,
		...personalDashboardRoutes,
		...collectionRoutes,
	],
})

router.beforeEach((to) => {
	const authStore = useAuthStore()
	if (!to.meta.noRequiresAuth && !authStore.isAuthenticated) {
		return `/login?next=${to.path}`
	}

	// fetch all courses and boards for the current user before entering the personal dashboard -> faster loading
	if (to.name == "personal-dashboard") useDashboardStore().fetchAll()
})

export const FOLA_DIGITAL = "FoLA | digital"

router.afterEach((to) => {
	// the outerHTML of #app is not controlled by vue, so we have to do this manually
	if (to.meta.useGridLayout) {
		document.getElementById("app")!.classList.remove("flex-layout")
		document.getElementById("app")!.classList.add("grid-layout")
	} else {
		document.getElementById("app")!.classList.remove("grid-layout")
		document.getElementById("app")!.classList.add("flex-layout")
	}

	// set the document title
	if (to.meta.title) {
		const title = to.meta.title()
		document.title = title ? title + " – " + FOLA_DIGITAL : FOLA_DIGITAL
	}
})

// matomo tracking
// TODO: add PREVIEW to if condition when ready
// Ensure Matomo script is loaded
// if (import.meta.env.MODE === "production" || import.meta.env.MODE === "development") {
// if (document.getElementById("matomo-script")) {
// 	console.log("Matomo script is loaded")

// 	// @ts-ignore
// 	if (window._paq) {
// 		console.log("Matomo tracking code is being executed")

// 		// @ts-ignore
// 		window._paq.push(["setCustomUrl", window.location.pathname])
// 		// @ts-ignore
// 		window._paq.push(["setDocumentTitle", document.title])
// 		// @ts-ignore
// 		window._paq.push(["trackPageView"])
// 		// @ts-ignore
// 		window._paq.push(["enableLinkTracking"])
// 	} else {
// 		console.log("Matomo tracking object (_paq) is not available")
// 	}
// } else {
// 	console.log("Matomo script is not loaded")
// }
if (document.getElementById("matomo-script")) {
	// console.log("Matomo script is loaded")

	// Define the _paq variable to avoid TypeScript errors
	const _paq = (window as any)._paq || []

	if (_paq) {
		// console.log("Matomo tracking code is being executed")

		try {
			_paq.push(["setCustomUrl", window.location.pathname])
			_paq.push(["setDocumentTitle", document.title])
			_paq.push(["trackPageView"])
			_paq.push(["enableLinkTracking"])
		} catch (error) {
			console.error("Error executing Matomo tracking code:", error)
		}
	} else {
		// console.log("Matomo tracking object (_paq) is not available")
	}
} else {
	// console.log("Matomo script is not loaded")
}

export default router
